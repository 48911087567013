body, html {
  font-family: 'Pangolin', cursive;
}


.btn {
  &.btn-primary {
    background: #008b02;
    border-color: #008b02;
    border-radius: 20px;
  }
}
